import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTable, faChartPie } from '@fortawesome/free-solid-svg-icons';
import { default as S } from 'react-select';
import graphQL from '../../../apis/graphQL';
import PieChartPopulation from '../../reusable/PieChartPopulation';
import PieChartPopulationByCategory from '../../reusable/PieChartPopulationByCategory';
import * as Chart from 'chart.js';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ProvidersContext } from '../../../contexts/ProvidersContext';
import AuthContextProvider from '../../../contexts/AuthContext';
import DatePicker from 'react-datepicker'
import numeral from 'numeral';
import "react-datepicker/dist/react-datepicker.css";
import { saveAs } from 'file-saver';
import asesREST from '../../../apis/asesREST';
import PieChart from '../../reusable/PieChart';
import { LoggedUserContext } from '../../../contexts/LoggedUser';

const mcoSelection = {
    id: 'mco',
    options: [
        {
            value: 'All MCO',
            label: 'All MCO'
        },
        {
            value: 'First Medical',
            label: 'First Medical'
        },
        {
            value: 'MMM',
            label: 'MMM'
        },
        {
            value: 'Molina Healthcare',
            label: 'Molina Healthcare'
        },
        {
            value: 'Plan De Salud Menonita',
            label: 'Plan De Salud Menonita'
        },
        {
            value: 'Triple S',
            label: 'Triple S'
        }
    ],
    defaultValue: {
        value: 'All MCO',
        label: 'All MCO'
    }
};

const ProvidersNetworkAndPopulation = () => {

    // Chart.plugins.unregister(ChartDataLabels);

    // showPopulationByMCO
    const [showGraphPopulationByMCO, setShowGraphPopulationByMCO] = useState(false);
    // showPopulationByCategory
    const [showGraphPopulationByCategory, setShowGraphPopulationByCategory] = useState(false);
    // startDate
    const [startDate, setStartDate] = useState(new Date());
    // pieChartPopulationByMCO
    const [pieChartPopulationByMCO, setPieChartPopulationByMCO] = useState([]);
    // pieChartPopulationByCategory
    const [pieChartPopulationByCategory, setPieChartPopulationByCategory] = useState([]);
    // tableByRateCell
    const [tableByRateCell, setTableByRateCell] = useState([]);
    // population
    const [population, setPopulation] = useState('');
    // filteredMonth
    const [filteredMonth, setFilteredMonth] = useState('');
    // mcoOptionSelected
    const [mcoOptionSelected, setMcoOptionSelected] = useState(mcoSelection.defaultValue);
    // barFilterText
    const [barFilterText, setBarFilterText] = useState('YTD');
    // mcoQuantity
    const [mcoQuantity, setMcoQuantity] = useState('');
    // subTotalFirstMedical
    const [subTotalFirstMedical, setSubTotalFirstMedical] = useState(0);
    // subTotalMMMMultiHealth
    const [subTotalMMMMultiHealth, setSubTotalMMMMultiHealth] = useState(0);
    // subTotalMolinaHealthCare
    const [subTotalMolinaHealthCare, setSubTotalMolinaHealthCare] = useState(0);
    // subTotalPlanDeSaludMenonita
    const [subTotalPlanDeSaludMenonita, setSubTotalPlanDeSaludMenonita] = useState(0);
    // subTotalTripleS
    const [subTotalTripleS, setSubTotalTripleS] = useState(0);

    const { scrollingClass } = useContext(LoggedUserContext);

    const { npi } = useContext(ProvidersContext);

    //Filters variables management
    useEffect(() => {
        console.log(`npi: ${npi}`);
        console.log(`filteredMonth: ${filteredMonth}`);
        console.log(`mcoOptionSelected: ${mcoOptionSelected.value}`);
        console.log('');
        console.log('');
    }, [npi, filteredMonth, mcoOptionSelected])

    //set filtered month to -1 month
    useEffect(() => {
        const date = new Date();
        setStartDate(new Date(date.setMonth(date.getMonth() - 1)));
        let month = date.getMonth()
        if (month < 9) {
            month = "0" + (month + 1);
        } else {
            month = (month + 1);
        }
        console.log(`${month}-01-${date.getFullYear()}`);
        setFilteredMonth(`${month}-01-${date.getFullYear()}`);
    }, [])

    // Change Filter Text
    useEffect(() => {
        if (startDate === undefined || startDate === null || startDate === '') {
            setBarFilterText('YTD')
            return;
        }
        switch (startDate.getMonth() + 1) {
            case 1:
                setBarFilterText('JAN' + ' ' + startDate.getFullYear())
                break;
            case 2:
                setBarFilterText('FEB' + ' ' + startDate.getFullYear())
                break;
            case 3:
                setBarFilterText('MAR' + ' ' + startDate.getFullYear())
                break;
            case 4:
                setBarFilterText('APR' + ' ' + startDate.getFullYear())
                break;
            case 5:
                setBarFilterText('MAY' + ' ' + startDate.getFullYear())
                break;
            case 6:
                setBarFilterText('JUN' + ' ' + startDate.getFullYear())
                break;
            case 7:
                setBarFilterText('JUL' + ' ' + startDate.getFullYear())
                break;
            case 8:
                setBarFilterText('AUG' + ' ' + startDate.getFullYear())
                break;
            case 9:
                setBarFilterText('SEP' + ' ' + startDate.getFullYear())
                break;
            case 10:
                setBarFilterText('OCT' + ' ' + startDate.getFullYear())
                break;
            case 11:
                setBarFilterText('NOV' + ' ' + startDate.getFullYear())
                break;
            case 12:
                setBarFilterText('DEC' + ' ' + startDate.getFullYear())
                break;

            default:
                break;
        }
    }, [startDate]);

    // summaryByNPI 
    useEffect(() => {
        setPopulation('');

        if (npi !== '') {
            (async () => {
                const response = await graphQL(`query{
                summaryByNPIForPopulation(NPI: ${npi}, month: "${filteredMonth}", carrierName: "${mcoOptionSelected.value}"){
                    totalPopulation
                    pmgNPI
                }
                }`);

                if (response.summaryByNPIForPopulation) {
                    const res = response.summaryByNPIForPopulation;
                    setPopulation(res.totalPopulation);

                } else if (response) {
                    setPopulation('noData')
                    // setLoading(false);
                    // setGraphQLErrors(response);
                }
            })()
        }
    }, [npi, filteredMonth, mcoOptionSelected]);

    // populationByMCO
    useEffect(() => {
        if (npi !== '') {

            (async () => {
                setPieChartPopulationByMCO([]);
                setMcoQuantity('');
                const response = await graphQL(`query{
                    populationByMCO(NPI: ${npi} ,month:"${filteredMonth}",carrierName:"${mcoOptionSelected.value}"){
                        populationByMCO
                        carrierName
                    }
                }`);
                if (response.populationByMCO) {
                    console.log('populationByMCO');
                    console.log(response.populationByMCO);
                    if (response.populationByMCO.length === 0) {
                        setPieChartPopulationByMCO('noData');
                        setMcoQuantity('noData');
                    } else {
                        setPieChartPopulationByMCO(response.populationByMCO);
                        let counter = 1;
                        response.populationByMCO.map(() => {
                            setMcoQuantity(counter);
                            counter++;
                        })
                    }
                } else if (response) {
                    // console.log(response);

                    setPieChartPopulationByMCO('noData');
                }
            })()
        }
    }, [npi, filteredMonth, mcoOptionSelected]);

    // populationByCategory
    useEffect(() => {
        if (npi !== '') {

            (async () => {
                setPieChartPopulationByCategory([]);
                const response = await graphQL(`query{
                    populationByCategory(NPI: ${npi} ,month:"${filteredMonth}",carrierName:"${mcoOptionSelected.value}"){
                        populationByCategory
                        category
                    }
                }`);
                if (response.populationByCategory) {
                    if (response.populationByCategory.length === 0) {
                        setPieChartPopulationByCategory('noData');
                    } else {
                        setPieChartPopulationByCategory(response.populationByCategory);
                    }
                } else if (response) {
                    console.log(response);
                    setPieChartPopulationByCategory('noData');
                }
            })()
        }
    }, [npi, filteredMonth, mcoOptionSelected]);

    // populationByRateCell
    useEffect(() => {
        if (npi !== '') {

            (async () => {
                setTableByRateCell([]);
                const response = await graphQL(`query{
                populationByRateCell(NPI: ${npi} ,month:"${filteredMonth}",carrierName:"${mcoOptionSelected.value}"){
                info{
                    carrierName
                    total
                }
                rateCell
                category
                }
            }`);
                if (response.populationByRateCell) {
                    if (response.populationByRateCell.length === 0) {
                        setTableByRateCell('noData');
                    } else {
                        setTableByRateCell(response.populationByRateCell);
                    }
                } else if (response) {
                    // console.log(response);
                    setTableByRateCell('noData');
                }
            })()
        }
    }, [npi, filteredMonth, mcoOptionSelected]);

    const printReport = async () => {
        try {
            asesREST.post('/generate-report', { npi: npi, month: filteredMonth }, {

                responseType: 'arraybuffer'
            }).then(response => {
                saveAs(new Blob([response.data]), 'report.xlsx')
            });
        } catch (error) {
            console.log(error);
        }
    }

    const noData = () => {
        return (
            <div className="message-no-data">
                <img src="images/image-no-data-to-display.png" alt="No data image" />
                <p className="p-primary">There is no available data to display for the current selection</p>
            </div>
        )
    }

    const renderCarriersData = (data, subTotals) => {
        let firstMedical = 0;
        let mmmMultiHealth = 0;
        let molinaHealthCare = 0;
        let planDeSaludMenotita = 0;
        let tripleS = 0;
        data.info.map((data) => {
            if (data.carrierName === 'First Medical') {
                firstMedical = data.total;
            } else if (data.carrierName === 'MMM Multi Health') {
                mmmMultiHealth = data.total;
            } else if (data.carrierName === 'Molina Health Care') {
                molinaHealthCare = data.total;
            } else if (data.carrierName === 'Plan de Salud Menonita') {
                planDeSaludMenotita = data.total;
            } else if (data.carrierName === 'Triple S') {
                tripleS = data.total;
            }

        });
        subTotals.firstMedical = subTotals.firstMedical + firstMedical;
        subTotals.mmmMultiHealth = subTotals.mmmMultiHealth + mmmMultiHealth;
        subTotals.molinaHealthCare = subTotals.molinaHealthCare + molinaHealthCare;
        subTotals.planDeSaludMenotita = subTotals.planDeSaludMenotita + planDeSaludMenotita;
        subTotals.tripleS = subTotals.tripleS + tripleS;

        return (
            <>
                <td>{numeral(firstMedical).format('0,0')}</td>
                <td>{numeral(mmmMultiHealth).format('0,0')}</td>
                <td>{numeral(molinaHealthCare).format('0,0')}</td>
                <td>{numeral(planDeSaludMenotita).format('0,0')}</td>
                <td>{numeral(tripleS).format('0,0')}</td>
                <td className="text-bold">{numeral(firstMedical + mmmMultiHealth + molinaHealthCare + planDeSaludMenotita + tripleS).format('0,0')}</td>
            </>
        )
    }

    const renderTable = () => {

        let counter = 0;
        let subTotals = {
            firstMedical: 0,
            mmmMultiHealth: 0,
            molinaHealthCare: 0,
            planDeSaludMenotita: 0,
            tripleS: 0
        }
        return tableByRateCell.map((data, index) => {
            if (counter === tableByRateCell.length - 1) {

                return (
                    <React.Fragment key={index}>
                        <tr key={`${data.category}${data.rateCell}`}>
                            <td>{data.category}</td>
                            <td>{data.rateCell}</td>
                            {renderCarriersData(data, subTotals)}
                        </tr>
                        <tr key={index}>
                            <td className="text-bold">Totals:</td>
                            <td className="text-bold"></td>
                            <td className="text-bold">{numeral(subTotals.firstMedical).format('0,0')}</td>
                            <td className="text-bold">{numeral(subTotals.mmmMultiHealth).format('0,0')}</td>
                            <td className="text-bold">{numeral(subTotals.molinaHealthCare).format('0,0')}</td>
                            <td className="text-bold">{numeral(subTotals.planDeSaludMenotita).format('0,0')}</td>
                            <td className="text-bold">{numeral(subTotals.tripleS).format('0,0')}</td>
                            <td className="text-bold">{numeral(subTotals.firstMedical + subTotals.mmmMultiHealth + subTotals.molinaHealthCare + subTotals.planDeSaludMenotita + subTotals.tripleS).format('0,0')}</td>
                        </tr>
                    </React.Fragment>
                )

            }
            counter++;

            return (
                <tr key={`${data.category}${data.rateCell}`}>
                    <td>{data.category}</td>
                    <td>{data.rateCell}</td>
                    {renderCarriersData(data, subTotals)}
                </tr>
            );
        });
    }

    const showTablePopulationByMCOButton = (value) => {
        if (pieChartPopulationByMCO !== 'noData') {
            setShowGraphPopulationByMCO(value);
        }
    }

    const showTablePopulationByCategoryButton = (value) => {
        if (pieChartPopulationByCategory !== 'noData') {
            setShowGraphPopulationByCategory(value);
        }
    }

    return (
        <AuthContextProvider>
            <div>
                {/* <!-- Main Content--> */}
                <div>
                    <div class="container-with-sidebar__heading-bar sticky">
                        <h1 class={scrollingClass}>Providers Network & Population Dashboard</h1>
                    </div>
                    {/* <!-- Barra filtros ProvidersNetworkAndPopulation --> */}
                    <div className="utility-bar-wrapper container-with-sidebar__content-block">
                        <div className="utility-bar">
                            <div className="utility-bar-col">
                                <span className="mr-1 p-primary text-bold"><i
                                    className="fas fa-filter mr-1"></i>Filter</span>
                                <div className="multiselect-linear-wrapper">
                                    {/* //TODO: style in Select verify the class select-form-combo in jagForms.css */}
                                    {/* MCO Dropdown */}
                                    <S options={mcoSelection.options} className="select-form-combo" id={mcoSelection.id} name={mcoSelection.id}
                                        onChange={(option) => {
                                            setMcoOptionSelected(option);
                                        }
                                        }
                                        defaultValue={mcoSelection.defaultValue} />
                                    {/* .MCO Dropdown */}

                                    {/* Select Month  */}
                                    <div style={{ display: 'inline' }}>
                                        <div className="date-picker">
                                            <DatePicker
                                                placeholderText="Select start month"
                                                selected={startDate}
                                                onChange={(date) => {
                                                    if (date !== null) {
                                                        let month = date.getMonth()
                                                        if (month < 9) {
                                                            month = "0" + (month + 1);
                                                        } else {
                                                            month = (month + 1);
                                                        }
                                                        setFilteredMonth(`${month}-01-${date.getFullYear()}`);
                                                    } else {
                                                        setFilteredMonth('');
                                                    }
                                                    setStartDate(date)
                                                }
                                                }
                                                selectsStart
                                                dateFormat="MM/yyyy"
                                                showMonthYearPicker
                                            />
                                        </div>
                                    </div>
                                    {/* .Select Month  */}

                                </div>
                            </div>
                            <div className="utility-bar-col text-right">
                                <a href="#" className="btn btn-link" onClick={printReport}><FontAwesomeIcon icon={faDownload} className="mr-1" /> Download Report</a>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Barra filtros ProvidersNetworkAndPopulation --> */}
                    <section className="container-with-sidebar__content-block">
                        {/* <!-- ProvidersNetworkAndPopulation Data Bar --> */}
                        <div className="dash-data-bar mt-2 mb-2">
                            <div className="dash-data-bar__title">
                                <h2>{barFilterText}</h2>
                            </div>
                            {mcoQuantity === 'noData' && population === 'noData' ?
                                <div class="dash-data-bar__information">
                                    <span class="p-primary">There is no available data to display for the current selection</span>
                                </div> :
                                <>
                                    <div className="dash-data-bar__information">
                                        <div className="dash-data-bar__element">
                                            <div className="dash-data-bar__label">Number of MCO</div>
                                            <div className="dash-data-bar__value">
                                                {mcoQuantity === '' ? <div className="loader-linear"><span></span><span></span><span></span></div> :
                                                    numeral(mcoQuantity).format('0,0')}
                                            </div>
                                        </div>
                                        <div className="dash-data-bar__element">
                                            <div className="dash-data-bar__label">Total Population</div>
                                            <div className="dash-data-bar__value">{population === '' ? <div className="loader-linear"><span></span><span></span><span></span></div> : numeral(population).format('0,0')}</div>
                                        </div>
                                        <div className="dash-data-bar__element">
                                            <div className="dash-data-bar__label"></div>
                                            <div className="dash-data-bar__value"><span className="money"></span>&nbsp;</div>
                                        </div>
                                        <div className="dash-data-bar__element">
                                            <div className="dash-data-bar__label"></div>
                                            <div className="dash-data-bar__value"><span className="money"></span>&nbsp;</div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        {/* <!-- End ProvidersNetworkAndPopulation Data Bar --> */}
                        {/* <!-- Grupo de Gráficos --> */}
                        <div className="row">
                            <div className="col">
                                {/* Population by MCO */}
                                {!showGraphPopulationByMCO ?
                                    <div className="panel">
                                        <div className="panel-head">
                                            <div className="title">
                                                <h3>Population by MCO</h3>
                                            </div>
                                            <div className="util text-right"><button className="btn" onClick={() => { showTablePopulationByMCOButton(true) }}><FontAwesomeIcon icon={faTable} style={{ color: 'rgb(55,136,176)' }} /></button></div>
                                        </div>
                                        <div className="panel-body">
                                            {
                                                pieChartPopulationByMCO.length === 0 ?
                                                    <div className="loader"></div> :
                                                    pieChartPopulationByMCO === 'noData' ? noData() :
                                                        <PieChartPopulation data={pieChartPopulationByMCO} />
                                            }
                                        </div>
                                    </div> :
                                    <div className="panel">
                                        <div className="panel-head">
                                            <div className="title">
                                                <h3>Population by MCO</h3>
                                            </div>
                                            <div className="util text-right"><button className="btn" onClick={() => { showTablePopulationByMCOButton(false) }}><FontAwesomeIcon icon={faChartPie} style={{ color: 'rgb(55,136,176)' }} /></button></div>
                                        </div>
                                        <div className="panel-body">
                                            <div className="table-scroll">
                                                <table className="table-dashboard">
                                                    <tbody>
                                                        <tr>
                                                            <th>Date</th>
                                                            <th className="text-right">Amount Paid</th>
                                                            <th className="text-right">Percentage</th>
                                                        </tr>
                                                        <PieChartPopulation data={pieChartPopulationByMCO} showTable={true} />
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {/* Population by MCO */}
                            </div>
                            <div className="col">
                                {/* Population by Category */}
                                {!showGraphPopulationByCategory ?
                                    <div className="panel">
                                        <div className="panel-head">
                                            <div className="title">
                                                <h3>Population by Category</h3>
                                            </div>
                                            <div className="util text-right"><button className="btn" onClick={() => { showTablePopulationByCategoryButton(true) }}><FontAwesomeIcon icon={faTable} style={{ color: 'rgb(55,136,176)' }} /></button></div>
                                        </div>
                                        <div className="panel-body">
                                            {
                                                pieChartPopulationByCategory.length === 0 ?
                                                    <div className="loader"></div> :
                                                    pieChartPopulationByCategory === 'noData' ? noData() :
                                                        <PieChartPopulation data={pieChartPopulationByCategory} />
                                            }
                                        </div>
                                    </div> :
                                    <div className="panel">
                                        <div className="panel-head">
                                            <div className="title">
                                                <h3>Population by Category</h3>
                                            </div>
                                            <div className="util text-right"><button className="btn" onClick={() => { showTablePopulationByCategoryButton(false) }}><FontAwesomeIcon icon={faChartPie} style={{ color: 'rgb(55,136,176)' }} /></button></div>
                                        </div>
                                        <div className="panel-body">
                                            <div className="table-scroll">
                                                <table className="table-dashboard">
                                                    <tbody>
                                                        <tr key="firstRow">
                                                            <th>Date</th>
                                                            <th className="text-right">Population</th>
                                                            <th className="text-right">Percentage</th>
                                                        </tr>
                                                        <PieChartPopulationByCategory data={pieChartPopulationByCategory} showTable={true} />
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {/* Population by MCO */}

                            </div>
                        </div>
                        <div className="panel mt-2">

                            <div className="panel-head">
                                <div className="title">
                                    <h3>Population by Rate Cell</h3>
                                </div>
                            </div>
                            {tableByRateCell === 'noData' ? noData() :
                                <div className="panel-body">
                                    {/* <!-- Todo: Convert Charts on Table for Dashaboard --> */}
                                    <div className="table-scroll">
                                        <table className="table-dashboard">
                                            <thead>
                                                <tr key="firstRow">
                                                    <th>Category</th>
                                                    <th>Rate Cell</th>
                                                    <th className="text-normal">First Medical</th>
                                                    <th className="text-normal">MMM Multi Health</th>
                                                    <th className="text-normal">Molina Health Care</th>
                                                    <th className="text-normal">Plan de Salud Menonita</th>
                                                    <th className="text-normal">Triple S</th>
                                                    <th className="text-normal">Totals</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {renderTable()}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                        </div>
                        {/* <!-- End Grupo de Gráficos --> */}
                    </section>
                </div>
            </div >
        </AuthContextProvider >
    );
}

export default ProvidersNetworkAndPopulation;