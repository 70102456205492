import React, { useContext, useEffect } from 'react';
import Input from '../../reusable/Input';
import { ProvidersContext } from '../../../contexts/ProvidersContext';
import history from '../../../history';
import { Link } from 'react-router-dom';
import AuthContextProvider from '../../../contexts/AuthContext';


const PermissionVerification = (props) => {

    const { npi } = useContext(ProvidersContext);

    useEffect(() => {
        if (npi === '') {
            history.push('/selectProviderType');
        }
    }, [npi]);

    return (
        // <AuthContextProvider>
        <div>

            <div className="container-with-sidebar__heading-bar sticky"><div className="row row--centered"> <div className="col-grow-2"><h2>Permission verification to access this {props.location.state.type || ' '} Provider Information. </h2></div>
                <div className="col">
                    <ul className="wizards-steps ml-3">
                        <li><span className="sr-only ">Step 1</span></li>
                        <li><span className="sr-only ">Step 2</span></li>
                        <li className="current"><span className="sr-only ">Step 3</span></li>
                        <li><span className="sr-only ">Step 4</span></li>
                        <li><span className="sr-only ">Step 5</span></li>
                    </ul>
                </div>
            </div>
            </div>

            <div className="container-with-sidebar__content-block">
                <div className="row gap-triple">
                    <div className="col instructions">
                        <p>You will be assigned as the <strong>Primary Representative</strong> to this provider. It will allow you to see its. billing information an also manage who else has permission to view it.</p>

                        <p>In order to proctect this providers information, you must provide a correct answer to each of the
                    questions asked. </p>
                        <p><img src="images/ilustration-verification-id.png" alt="Search Provider" className="mb-2 mt-2" /></p>
                        <p className="text-bold">Not a Primary Representative?</p>
                        <p>There must be a Primary Representative managing permissions for this provider. After a Primary Representative is registered, you can ask for permission to access this providers information. </p>
                    </div>
                    <div className="col-grow-3">
                        <p className="text-bold mb-2">Provider Information</p>

                        {/* <p className="message message-error">You have one or more wrong answers. Check your responses and try again.</p> */}

                        <div className="form-group mt-3">
                            <label htmlFor="npi">NPI:</label>
                            <input type="text" readOnly value={npi} />
                        </div>

                        {/* ssn field*/}
                        <Input
                            type="text"
                            label="SSN last 4 digits:"
                            id="ssn"
                            placeholder="Ex. 1234"
                            errorMessage=""
                            customClass="input-w100"
                            disabled={false}
                            optional={false}
                            mt2
                        />
                        {/* .ssn field */}
                        {/* licenseNumber field*/}
                        <Input
                            type="text"
                            label="License Number:"
                            id="licenseNumber"
                            placeholder="Ex. 0000000"
                            errorMessage=""
                            disabled={false}
                            optional={false}
                            mt2
                        />
                        {/* .licenseNumber field */}


                        <p className="text-bold mb-2 mt-3">Address:</p>

                        {/* addressLine1 field*/}
                        <Input
                            type="text"
                            label="Address line 1:"
                            id="addressLine1"
                            placeholder="Street and number."
                            errorMessage=""
                            customClass="input-w300"
                            disabled={false}
                            optional={false}
                            mt2
                        />
                        {/* .addressLine1 field */}
                        {/* addressLine2 field*/}
                        <Input
                            type="text"
                            label="Address line 2:"
                            id="addressLine2"
                            placeholder="Apartment, suite, unit, etc.."
                            errorMessage=""
                            customClass="input-w300"
                            disabled={false}
                            optional={false}
                            mt2
                        />
                        {/* .addressLine2 field */}
                        {/* addressLine3 field*/}
                        <Input
                            type="text"
                            label="Address line 3:"
                            id="addressLine3"
                            placeholder="Apartment, suite, unit, etc.."
                            errorMessage=""
                            customClass="input-w300"
                            disabled={false}
                            optional={true}
                            mt2
                        />
                        {/* .addressLine3 field */}

                        {/* city field*/}
                        <Input
                            type="text"
                            label="City or Town:"
                            id="city"
                            placeholder=""
                            errorMessage=""
                            customClass="input-w200"
                            disabled={false}
                            optional={false}
                            mt2
                        />
                        {/* .city field */}
                        {/* state field*/}
                        <Input
                            type="text"
                            label="State or Territory:"
                            id="state"
                            placeholder=""
                            errorMessage=""
                            customClass="input-w200"
                            disabled={false}
                            optional={false}
                            mt2
                        />
                        {/* .state field */}
                        {/* zip field*/}
                        <Input
                            type="text"
                            label="Zip Code:"
                            id="zip"
                            placeholder="Ex. 00000"
                            errorMessage=""
                            customClass="input-w100"
                            disabled={false}
                            optional={false}
                            mt2
                        />
                        {/* .zip field */}

                        <p className="text-bold mb-2 mt-3">Contact Information:</p>
                        {/* officePhoneNumber field*/}
                        <Input
                            type="text"
                            label="Office Phone Number:"
                            id="officePhoneNumber"
                            placeholder="Ex. (555) 123-4567"
                            errorMessage=""
                            customClass=""
                            disabled={false}
                            optional={false}
                            mt2
                        />
                        {/* .officePhoneNumber field */}
                        {/* officeFaxNumber field*/}
                        <Input
                            type="text"
                            label="Office Fax Number:"
                            id="officeFaxNumber"
                            placeholder="Ex. (555) 123-4567"
                            errorMessage=""
                            customClass=""
                            disabled={false}
                            optional={true}
                            mt2
                        />
                        {/* .officeFaxNumber field */}


                        <p className="text-bold mb-2 mt-3">Other:</p>

                        <div className="form-group">
                            <label htmlFor="other">Primary Medical Group:</label>
                            <input type="text" id="primaryMedicalGroup" className="input-w300"
                                placeholder="Ex. Grupo Médico Puerto Rico" />
                            <div className="message-instructions">Hint: PMG</div>
                        </div>

                        <div className="form-group mt-3">
                            <Link to="/selectProviderType" className="btn btn-secondary mr-2">Cancel</Link>
                            <button type="button" className="btn btn-primary"
                                onClick={() => { history.push('/disclosure') }}
                            >Continue</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        // </AuthContextProvider>
    );
}

export default PermissionVerification;

