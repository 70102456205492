import React, { createContext, useState } from "react";
import socketIOClient from "socket.io-client";
export const SocketContext = createContext();

const SocketContextProvider = (props) => {
  // socket
  let socketDefinition = socketIOClient(
    "https://ases-service-center-api.citriom.io"
  );
  const [socketIO, setSocketIO] = useState(socketDefinition);

  return (
    <SocketContext.Provider value={{ socketIO, setSocketIO }}>
      {props.children}
    </SocketContext.Provider>
  );
};

export default SocketContextProvider;
