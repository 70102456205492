import axios from "axios";

//Very important to use the headers in this way. If you use a plain headers, localStorage don't update correctly
export default axios.create({
  // baseURL: process.env.REACT_APP_DEV_BASE_URL,
  baseURL: "https://ases-service-center-api.citriom.io",
  headers: {
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem("token")}`;
      },
    },
  },
});
