import React, { useState, useEffect, useContext } from 'react';
import Input from '../reusable/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import graphQL from '../../apis/graphQL';
import history from '../../history';
import { SaveLoginActionToGoogleAnalytics } from '../Tracking';
import TermsAndAgreementsContextProvider, { TermsAndAgreementsContext } from '../../contexts/TermsAndAgreementsContext';

const Main = () => {
    // loading
    const [loading, setLoading] = useState(false);
    // graphQL
    const [graphQLErrors, setGraphQLErrors] = useState(false);
    // formState
    const [formState, setFormState] = useState({
        values: {}
    });

    const { protectMain } = useContext(TermsAndAgreementsContext);

    useEffect(() => {
        if (protectMain) {
            history.push('/');
        }
    }, []);

    const login = async () => {
        const { email, password } = formState.values;
        setGraphQLErrors(false);
        setLoading(true);
        const response = await graphQL(`mutation{
            login(email: "${email}", password: "${password}"){
                _id
                token
                firstTimeLogin
            }
        }`);
        setLoading(false);
        console.log(response);
        if (response.login) {
            SaveLoginActionToGoogleAnalytics(response.login._id);
            const { token, firstTimeLogin } = response.login;
            setLoading(false);
            localStorage.setItem('token', token);
            if (firstTimeLogin) {
                history.push('/completePersonalInformation');
            } else {
                history.push('/verificationCode');
            }
        } else if (response) {
            setLoading(false);
            setGraphQLErrors(response);
        }
    }

    const onChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const type = e.target.type;
        const checked = e.target.checked
        //Change state
        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [name]: type === 'checkbox' ? checked : value
            }
        }));
    }

    return (
        <TermsAndAgreementsContextProvider>
            <div>
                <div className="login-wrapper mt-1">
                    <div className="container">
                        <div className="row gap-double">
                            <div className="col">
                                <div className="panel-clear">
                                    <h2>Access your account</h2>
                                    <form onSubmit={(e) => e.preventDefault()} className="fancy mb-0">
                                        {/* email field*/}
                                        <Input
                                            type="email"
                                            label="Email"
                                            id="email"
                                            placeholder="email"
                                            onChange={onChange}
                                            invisibleFancy
                                        />
                                        {/* .email field */}
                                        {/* password field*/}
                                        <Input
                                            type="password"
                                            label="Password"
                                            id="password"
                                            placeholder="password"
                                            onChange={onChange}
                                            invisibleFancy
                                            mantainPassword
                                        />
                                        {/* .password field */}
                                        {/* Error Message */}
                                        {graphQLErrors ?
                                            <span
                                                style={{ color: 'red' }}>{graphQLErrors}
                                            </span> : <span></span>
                                        }
                                        {/* .Error Message */}


                                        <button
                                            type="submit"
                                            onClick={login}
                                            className="btn btn-block btn-primary mt-1 mb-1"
                                        >{loading ? <div className="loader-linear--bg-white"><span></span><span></span><span></span></div> : 'Log in'}</button>

                                        <p className="text-right mb-0" style={{ fontSize: '16px' }}>
                                            <Link
                                                to="/emailPasswordRecovery"
                                                className="secondary"
                                            >Forgot my password</Link>
                                        </p>
                                    </form>
                                </div>
                            </div>
                            <div className="col">
                                <div className="panel-clear">
                                    <h2>New user?</h2>
                                    <div className="fancy">
                                        <p>You must create a personal account to access the portal.</p>
                                        <p>
                                            Once the account is created, additional security information will be required in order to
                                            enable the full functionality of the portal.
                    </p>
                                    </div>
                                    <p className="text-right mt-2">
                                        <Link to={`/createAccountStep1`} className="btn btn-link-fancy">
                                            <span><FontAwesomeIcon icon={faChevronRight} /> Create account</span>
                                        </Link>
                                    </p>
                                    <p className="p-primary mt-2 mb-0">
                                        <Link
                                            to="/faq"
                                            className="secondary"
                                        >Need help creating your account?</Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
                {/* < !-- < Chat ></Chat > --> */}
            </div >
        </TermsAndAgreementsContextProvider>
    );
}


export default Main;