import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faTable, faChartPie } from '@fortawesome/free-solid-svg-icons';
import { default as S } from 'react-select';
import graphQL from '../../../apis/graphQL';
import BarWithLineChart from '../../reusable/BarWithLineChart';
import PieChart from '../../reusable/PieChart';
import * as Chart from 'chart.js';
// import ChartDataLabels from 'chartjs-plugin-datalabels';
import LineChartFilledTwoDatasets from '../../reusable/LineChartFilledTwoDatasets';
import SingleLineChart from '../../reusable/SingleLineChart';
import { ProvidersContext } from '../../../contexts/ProvidersContext';
import AuthContextProvider, { AuthContext } from '../../../contexts/AuthContext';
import DatePicker from 'react-datepicker'
import numeral from 'numeral';
import "react-datepicker/dist/react-datepicker.css";
import history from '../../../history';
import { SocketContext } from '../../../contexts/SocketContext';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import ReactTable from '../../reusable/ReactTable';
import {
    useTable,
    useGroupBy,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
} from 'react-table';
import { LoggedUserContext } from '../../../contexts/LoggedUser';

const updatedAlert = withReactContent(Swal)

const rangeSelection = {
    id: 'range',
    options: [
        {
            value: '',
            label: 'Select Range'
        },
        {
            value: 'byMonth',
            label: 'By month'
        },
        {
            value: 'greaterThan',
            label: 'Greather than'
        },
        {
            value: 'lessThan',
            label: 'Less than'
        },
        {
            value: 'between',
            label: 'Between'
        }
    ],
    defaultValue: {
        value: '',
        label: 'Select Range'
    }
};

const mcoSelection = {
    id: 'mco',
    options: [
        {
            value: 'All MCO',
            label: 'All MCO'
        },
        {
            value: 'First Medical',
            label: 'First Medical'
        },
        {
            value: 'MMM',
            label: 'MMM'
        },
        {
            value: 'Molina Healthcare',
            label: 'Molina Healthcare'
        },
        {
            value: 'Plan De Salud Menonita',
            label: 'Plan De Salud Menonita'
        },
        {
            value: 'Triple S',
            label: 'Triple S'
        }
    ],
    defaultValue: {
        value: 'All MCO',
        label: 'All MCO'
    }
};

const timelinessSelection = {
    id: 'timeliness',
    options: [
        {
            value: '',
            label: 'By Timeliness'
        },
        {
            value: '1',
            label: 'All Range'
        },
        {
            value: '2',
            label: 'Less than 30d'
        },
        {
            value: '3',
            label: '60d'
        },
        {
            value: '4',
            label: '90d'
        },
        {
            value: '5',
            label: 'More than 90d'
        }
    ],
    defaultValue: {
        value: '',
        label: 'By Timeliness'
    }
};

const Billing = () => {

    // Chart.plugins.unregister(ChartDataLabels);

    // showTable
    const [showTableAmountPaidByMCO, setShowTableAmountPaidByMCO] = useState(false);
    // showTableTransactionsByStatus
    const [showTableTransactionsByStatus, setShowTableTransactionsByStatus] = useState(false);
    // showTableTimelinessForRegularClaims
    const [showTableTimelinessForRegularClaims, setShowTableTimelinessForRegularClaims] = useState(false);
    // showTableTimelinessForAdjustedClaims
    const [showTableTimelinessForAdjustedClaims, setShowTableTimelinessForAdjustedClaims] = useState(false);
    // showTableTotalAmountAndPaidMonth
    const [showTableTotalAmountAndPaidMonth, setShowTableTotalAmountAndPaidMonth] = useState(false);
    // showTableEncounters
    const [showTableEncounters, setShowTableEncounters] = useState(false);

    // startDate
    const [startDate, setStartDate] = useState();
    // endDate
    const [endDate, setEndDate] = useState();
    // pieChartAmountPaidByMCO
    const [pieChartAmountPaidByMCO, setPieChartAmountPaidByMCO] = useState([]);
    // pieChartTransactionsByStatus
    const [pieChartTransactionsByStatus, setPieChartTransactionsByStatus] = useState([]);
    // timelinessForRegularClaims
    const [timelinessForRegularClaims, setTimelinessForRegularClaims] = useState([]);
    // timelinessForAdjustedClaims
    const [timelinessForAdjustedClaims, setTimelinessForAdjustedClaims] = useState([]);
    // amountPaidAmountAllowedByMonth
    const [amountPaidAmountAllowedByMonth, setAmountPaidAmountAllowedByMonth] = useState([]);
    // encountersByMonth
    const [encountersByMonth, setEncountersByMonth] = useState([]);
    // encounters
    const [encounters, setEncounters] = useState('');
    // claims
    const [claims, setClaims] = useState('');
    // amountAllowed
    const [amountAllowed, setAmountAllowed] = useState('');
    // amountPaid
    const [amountPaid, setAmountPaid] = useState('');
    // selectedMonthFilterOption
    const [selectedMonthFilterOption, setSelectedMonthFilterOption] = useState('');
    // filteredMonth
    const [filteredMonth, setFilteredMonth] = useState('');
    // endFilteredMonth
    const [endFilteredMonth, setEndFilteredMonth] = useState('');
    // optionSelected
    const [optionSelected, setOptionSelected] = useState(rangeSelection.defaultValue);
    // mcoOptionSelected
    const [mcoOptionSelected, setMcoOptionSelected] = useState(mcoSelection.defaultValue);
    // barFilterText
    const [barFilterText, setBarFilterText] = useState('YTD');

    const { npi } = useContext(ProvidersContext);

    const { scrollingClass } = useContext(LoggedUserContext);

    const { socketIO } = useContext(SocketContext);

    //If Admin deletes an npi from provider
    useEffect(() => {
        if (socketIO) {
            socketIO.on('npiDisconnectedFromUser', (socketNPI) => {
                if (npi === socketNPI) {
                    updatedAlert.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Access to ' + npi + ' changed.',
                        showConfirmButton: false,
                        timer: 1500
                    }).then(() => {
                        history.push('/providersList');
                    })

                }
            })
        }
    }, [socketIO]);

    //Filters variables management
    useEffect(() => {
    }, [npi, selectedMonthFilterOption, filteredMonth, endFilteredMonth, mcoOptionSelected])

    // Change Filter Text
    useEffect(() => {
        if (startDate === undefined || startDate === null || startDate === '') {
            setBarFilterText('YTD')
            return;
        }
        switch (startDate.getMonth() + 1) {
            case 1:
                setBarFilterText('JAN' + ' ' + startDate.getFullYear())
                break;
            case 2:
                setBarFilterText('FEB' + ' ' + startDate.getFullYear())
                break;
            case 3:
                setBarFilterText('MAR' + ' ' + startDate.getFullYear())
                break;
            case 4:
                setBarFilterText('APR' + ' ' + startDate.getFullYear())
                break;
            case 5:
                setBarFilterText('MAY' + ' ' + startDate.getFullYear())
                break;
            case 6:
                setBarFilterText('JUN' + ' ' + startDate.getFullYear())
                break;
            case 7:
                setBarFilterText('JUL' + ' ' + startDate.getFullYear())
                break;
            case 8:
                setBarFilterText('AUG' + ' ' + startDate.getFullYear())
                break;
            case 9:
                setBarFilterText('SEP' + ' ' + startDate.getFullYear())
                break;
            case 10:
                setBarFilterText('OCT' + ' ' + startDate.getFullYear())
                break;
            case 11:
                setBarFilterText('NOV' + ' ' + startDate.getFullYear())
                break;
            case 12:
                setBarFilterText('DEC' + ' ' + startDate.getFullYear())
                break;

            default:
                break;
        }
    }, [startDate]);

    //If npi === 0 then redirects to providersList
    useEffect(() => {
        if (npi === 0) {
            history.push('/providersList');
        }
    }, [npi]);

    // summaryByNPI 
    useEffect(() => {
        setEncounters('');
        setClaims('');
        setAmountAllowed('');
        setAmountPaid('');
        if (npi !== '') {
            (async () => {
                const response = await graphQL(`query{
                    summaryByNPI(NPI:${npi},month:"${filteredMonth}",filterType:"${selectedMonthFilterOption}",carrierName:"${mcoOptionSelected.value}"){
                        encounters
                        claims
                        amountAllowed
                        amountPaid
                        NPI
                    }
                }`);

                if (response.summaryByNPI) {
                    const res = response.summaryByNPI;
                    setEncounters(res.encounters);
                    setClaims(res.claims);
                    setAmountAllowed(res.amountAllowed);
                    setAmountPaid(res.amountPaid);
                } else if (response) {
                    setEncounters('noData');
                    setClaims('noData');
                    setAmountAllowed('noData');
                    setAmountPaid('noData');
                    // setLoading(false);
                    // setGraphQLErrors(response);
                }
            })()
        }
    }, [npi, selectedMonthFilterOption, filteredMonth, endFilteredMonth, mcoOptionSelected]);

    // amountPaidAmountAllowedByMonth
    useEffect(() => {
        if (npi !== '') {
            (async () => {
                setAmountPaidAmountAllowedByMonth([]);
                // setLoading(true);
                const response = await graphQL(`query{
            amountPaidAmountAllowedByMonth(NPI:${npi}){
                date{
                year
                month
                }
                amountPaid
                amountAllowed
            }
        }`);
                // setLoading(false);
                if (response.amountPaidAmountAllowedByMonth) {
                    console.log('amountPaidAmountAllowedByMonth');
                    console.log(response.amountPaidAmountAllowedByMonth);
                    if (response.amountPaidAmountAllowedByMonth.length === 0) {
                        setAmountPaidAmountAllowedByMonth('noData');
                    } else {
                        setAmountPaidAmountAllowedByMonth(response.amountPaidAmountAllowedByMonth);
                    }
                } else if (response) {
                    setAmountPaidAmountAllowedByMonth('noData');
                    // setLoading(false);
                    // setGraphQLErrors(response);
                }
            })()
        }
    }, [npi]);

    // amountPaidByCarrierName
    useEffect(() => {
        if (npi !== '') {
            (async () => {
                setPieChartAmountPaidByMCO([]);
                const response = await graphQL(`query{
                    amountPaidByCarrierName(NPI:${npi},month:"${filteredMonth}",filterType:"${selectedMonthFilterOption}",carrierName:"${mcoOptionSelected.value}"){
                        amountPaid
                        carrierName
                    }
                }`);
                if (response.amountPaidByCarrierName) {
                    // console.log('Amount Paid By Carrier Name');
                    // console.log(response.amountPaidByCarrierName);
                    if (response.amountPaidByCarrierName.length === 0) {
                        setPieChartAmountPaidByMCO('noData');
                    } else {
                        setPieChartAmountPaidByMCO(response.amountPaidByCarrierName);
                    }
                } else if (response) {
                    console.log('ups');
                    setPieChartAmountPaidByMCO('noData');
                }
            })()
        }
    }, [npi, selectedMonthFilterOption, filteredMonth, endFilteredMonth, mcoOptionSelected]);

    // transactionsByStatus
    useEffect(() => {
        if (npi !== '') {
            (async () => {
                setPieChartTransactionsByStatus([]);
                const response = await graphQL(`query{
                            transactionsByStatus(NPI:${npi},month:"${filteredMonth}",filterType:"${selectedMonthFilterOption}",carrierName:"${mcoOptionSelected.value}"){
                                total
                                transactionStatus
                            }
                        }`);
                if (response.transactionsByStatus) {
                    // console.log('Transactions By Status');
                    // console.log(response.transactionsByStatus);
                    if (response.transactionsByStatus === 0) {
                        setPieChartTransactionsByStatus(response.transactionsByStatus);
                    } else {
                        setPieChartTransactionsByStatus('noData');
                    }
                } else if (response) {
                    setPieChartTransactionsByStatus('noData');
                }
            })()
        }
    }, [npi, selectedMonthFilterOption, filteredMonth, endFilteredMonth, mcoOptionSelected]);

    // timelinessForRegularClaims
    useEffect(() => {
        if (npi !== '') {
            (async () => {
                setTimelinessForRegularClaims([]);
                const response = await graphQL(`query{
                    timelinessForRegularClaims(NPI:${npi},month:"${filteredMonth}",filterType:"${selectedMonthFilterOption}",carrierName:"${mcoOptionSelected.value}"){
                        transactions{
                            transactions0To30
                            transactions31To60
                            transactions61To90
                            transactionsOver91
                        }
                        amountPaid{
                            amountPaid0To30
                            amountPaid31To60
                            amountPaid61To90
                            amountPaidOver91
                        }
                }
            }`);

                if (response.timelinessForRegularClaims) {
                    console.log('Timeliness for regular claims');
                    console.log(response.timelinessForRegularClaims);
                    setTimelinessForRegularClaims(response.timelinessForRegularClaims);
                } else if (response) {
                    setTimelinessForRegularClaims('noData');
                }
            })()
        }
    }, [npi, selectedMonthFilterOption, filteredMonth, endFilteredMonth, mcoOptionSelected]);


    // timelinessForAdjustedClaims
    useEffect(() => {
        if (npi !== '') {
            (async () => {
                setTimelinessForAdjustedClaims([]);
                const response = await graphQL(`query{
                    timelinessForAdjustedClaims(NPI:${npi},month:"${filteredMonth}",filterType:"${selectedMonthFilterOption}",carrierName:"${mcoOptionSelected.value}"){
                        transactions{
                            transactions0To30
                            transactions31To60
                            transactions61To90
                            transactionsOver91
                        }
                        amountPaid{
                            amountPaid0To30
                            amountPaid31To60
                            amountPaid61To90
                            amountPaidOver91
                        }
                    }
                }`);

                if (response.timelinessForAdjustedClaims) {
                    setTimelinessForAdjustedClaims(response.timelinessForAdjustedClaims);
                } else if (response) {
                    setTimelinessForAdjustedClaims('noData');
                }
            })()
        }
    }, [npi, selectedMonthFilterOption, filteredMonth, endFilteredMonth, mcoOptionSelected]);

    // encountersByMonth
    useEffect(() => {
        if (npi !== '') {
            setEncountersByMonth([]);
            (async () => {
                //setLoading(true);
                const response = await graphQL(`query{
                encountersByMonth(NPI:${npi}){
                    date{
                    year
                    month
                    }
                    encounters
                }
            }`);
                //setLoading(false);
                if (response.encountersByMonth) {
                    if (response.encountersByMonth.length === 0) {
                        setEncountersByMonth('noData');
                    } else {
                        setEncountersByMonth(response.encountersByMonth);
                    }
                } else if (response) {
                    setEncountersByMonth('noData');
                    // setLoading(false);
                    //setGraphQLErrors(response);
                }
            })()
        }
    }, [npi]);

    const printReport = async () => {

    }

    const noData = () => {
        return (
            <div className="message-no-data">
                <img src="images/graph1.png" width="420" alt="No data image" />
                {/* <img src="images/image-no-data-to-display.png" alt="No data image" /> */}
                {/* <p className="p-primary">There is no available data to display for the current selection</p> */}
            </div>
        )
    }

    const showTableAmountPaidByMCOButton = (value) => {
        if (pieChartAmountPaidByMCO !== 'noData') {
            setShowTableAmountPaidByMCO(value);
        }
    }
    const showTableTransactionsByStatusButton = (value) => {
        if (pieChartTransactionsByStatus !== 'noData') {
            setShowTableTransactionsByStatus(value);
        }
    }
    const showTableTimelinessForRegularClaimsButton = (value) => {
        if (timelinessForRegularClaims !== 'noData') {
            setShowTableTimelinessForRegularClaims(value);
        }
    }
    const showTableTimelinessForAdjustedClaimsButton = (value) => {
        if (timelinessForAdjustedClaims !== 'noData') {
            setShowTableTimelinessForAdjustedClaims(value);
        }
    }

    const showTableTotalAmountAndPaidMonthButton = (value) => {
        if (amountPaidAmountAllowedByMonth !== 'noData') {
            setShowTableTotalAmountAndPaidMonth(value);
        }
    }

    const showTableEncountersButton = (value) => {
        if (encountersByMonth !== 'noData') {
            setShowTableEncounters(value);
        }
    }

    const renderTableAmountPaidByMCO = () => {
        let total = 0;
        pieChartAmountPaidByMCO.map((data) => {
            total += data.amountPaid;
        });

        return (
            pieChartAmountPaidByMCO.map((data, index) => {
                if (index === pieChartAmountPaidByMCO.length - 1) {
                    return (
                        <React.Fragment key={index}>
                            <tr >
                                <td>{data.carrierName}</td>
                                <td className="text-right">${numeral(data.amountPaid).format('0,0.00')}</td>
                                <td className="text-right">{numeral(data.amountPaid / total * 100).format('0,0.00')}%</td>
                            </tr>
                            <tr className="text-bold">
                                <td>Total</td>
                                <td className="text-right">${numeral(total).format('0,0.00')}</td>
                                <td className="text-right">100%</td>
                            </tr>
                        </React.Fragment>
                    )
                } else {
                    return (
                        <tr key={index}>
                            <td>{data.carrierName}</td>
                            <td className="text-right">${numeral(data.amountPaid).format('0,0.00')}</td>
                            <td className="text-right">{numeral(data.amountPaid / total * 100).format('0,0.00')}%</td>
                        </tr>
                    )
                }
            })
        );
    }

    const renderTableTimelinessForRegularClaims = () => {
        let totalTransactions = 0;
        let totalAmountPaid = 0;
        let transactionsArray = [];
        let amountPaidArray = [];
        Object.values(timelinessForRegularClaims.transactions).forEach((value) => {
            totalTransactions += value;
            transactionsArray.push(value);
        });
        Object.values(timelinessForRegularClaims.amountPaid).forEach((value) => {
            totalAmountPaid += value;
            amountPaidArray.push(value);
        });

        const rowNames = ['<= 30 d', '50 d', '90 d', 'more'];

        return rowNames.map((row, x) => {
            console.log(x);
            if (x === rowNames.length - 1) {
                return (
                    <React.Fragment key={x}>
                        <tr >
                            <td>{row}</td>
                            <td className="text-right">{numeral(transactionsArray[x]).format('0,0')}</td>
                            <td className="text-right">${numeral(amountPaidArray[x]).format('0,0.00')}</td>
                        </tr>
                        <tr className="text-bold">
                            <td>Total</td>
                            <td className="text-right">{numeral(totalTransactions).format('0,0')}</td>
                            <td className="text-right">${numeral(totalAmountPaid).format('0,0.00')}</td>
                        </tr>
                    </React.Fragment>
                )
            } else {
                return (
                    <tr key={x}>
                        <td>{row}</td>
                        <td className="text-right">{numeral(transactionsArray[x]).format('0,0')}</td>
                        <td className="text-right">${numeral(amountPaidArray[x]).format('0,0.00')}</td>
                    </tr>
                )
            }
        });
    }

    const renderTableTimelinessForAdjustedClaims = () => {
        let totalTransactions = 0;
        let totalAmountPaid = 0;
        let transactionsArray = [];
        let amountPaidArray = [];
        Object.values(timelinessForAdjustedClaims.transactions).forEach((value) => {
            totalTransactions += value;
            transactionsArray.push(value);
        });
        Object.values(timelinessForAdjustedClaims.amountPaid).forEach((value) => {
            totalAmountPaid += value;
            amountPaidArray.push(value);
        });

        const rowNames = ['<= 30 d', '50 d', '90 d', 'more'];

        return rowNames.map((row, x) => {
            console.log(x);
            if (x === rowNames.length - 1) {
                return (
                    <React.Fragment key={x}>
                        <tr >
                            <td>{row}</td>
                            <td className="text-right">{numeral(transactionsArray[x]).format('0,0')}</td>
                            <td className="text-right">${numeral(amountPaidArray[x]).format('0,0.00')}</td>
                        </tr>
                        <tr className="text-bold">
                            <td>Total</td>
                            <td className="text-right">{numeral(totalTransactions).format('0,0')}</td>
                            <td className="text-right">${numeral(totalAmountPaid).format('0,0.00')}</td>
                        </tr>
                    </React.Fragment>
                )
            } else {
                return (
                    <tr key={x}>
                        <td>{row}</td>
                        <td className="text-right">{numeral(transactionsArray[x]).format('0,0')}</td>
                        <td className="text-right">${numeral(amountPaidArray[x]).format('0,0.00')}</td>
                    </tr>
                )
            }
        });
    }

    const renderTableTransactionsByStatus = () => {
        let total = 0;
        pieChartTransactionsByStatus.map((data) => {
            total += data.total;
        });

        return (
            pieChartTransactionsByStatus.map((data, index) => {
                if (index === pieChartTransactionsByStatus.length - 1) {
                    return (
                        <React.Fragment key={index}>
                            <tr >
                                <td>{data.transactionStatus}</td>
                                <td className="text-right">{numeral(data.total).format('0,0')}</td>
                                <td className="text-right">{numeral(data.total / total * 100).format('0,0.00')}%</td>
                            </tr>
                            <tr className="text-bold">
                                <td>Total</td>
                                <td className="text-right">{numeral(total).format('0,0')}</td>
                                <td className="text-right">100%</td>
                            </tr>
                        </React.Fragment>
                    )
                } else {
                    return (
                        <tr key={index}>
                            <td>{data.transactionStatus}</td>
                            <td className="text-right">{numeral(data.total).format('0,0')}</td>
                            <td className="text-right">{numeral(data.total / total * 100).format('0,0.00')}%</td>
                        </tr>
                    )

                }
            })
        );
    }

    return (
        <AuthContextProvider>
            <div>
                {/* <!-- Main Content--> */}
                <div>
                    <div className="container-with-sidebar__heading-bar sticky">
                        <h1 className={scrollingClass}>Billing Dashboard</h1>
                    </div>

                    {/* <!-- Barra filtros Billing --> */}
                    <div className="utility-bar-wrapper container-with-sidebar__content-block">
                        <div className="utility-bar">
                            <div className="utility-bar-col">
                                <span className="mr-1 p-primary text-bold"><i
                                    className="fas fa-filter mr-1"></i>Filter</span>
                                <div className="multiselect-linear-wrapper">
                                    {/* //TODO: style in Select verify the class select-form-combo in jagForms.css */}
                                    {/* Month Filter Dropdown */}
                                    <S options={rangeSelection.options} className="select-form-combo" id={rangeSelection.id} name={rangeSelection.id} onChange={(option) => {
                                        setOptionSelected(option);
                                        setSelectedMonthFilterOption(option.value)
                                    }
                                    }
                                        value={optionSelected}
                                    />
                                    {/* .Month Filter Dropdown */}

                                    {selectedMonthFilterOption === '' ?
                                        <input type="text" style={{ marginRight: "25px" }} disabled />
                                        : selectedMonthFilterOption == '4' ?
                                            // Two dates selected
                                            <div style={{ display: 'inline' }}>
                                                <div className="date-picker">
                                                    <DatePicker
                                                        isClearable
                                                        placeholderText="Select start month"
                                                        selected={startDate}
                                                        onChange={(date) => {
                                                            if (date !== null) {
                                                                let month = date.getMonth()
                                                                if (month < 9) {
                                                                    month = "0" + (month + 1);
                                                                } else {
                                                                    month = (month + 1);
                                                                }
                                                                setFilteredMonth(`${month}-01-${date.getFullYear()}`);
                                                            } else {
                                                                setOptionSelected(rangeSelection.defaultValue);
                                                                setFilteredMonth('');
                                                                setSelectedMonthFilterOption('');
                                                            }
                                                            setStartDate(date)
                                                        }
                                                        }
                                                        selectsStart
                                                        dateFormat="MM/yyyy"
                                                        showMonthYearPicker
                                                    />
                                                </div>
                                                <div className="date-picker">
                                                    <DatePicker
                                                        placeholderText="Select end month"
                                                        selected={endDate}
                                                        onChange={(date) => {
                                                            let month = date.getMonth()
                                                            if (month < 9) {
                                                                month = "0" + (month + 1);
                                                            } else {
                                                                month = (month + 1);
                                                            }
                                                            setEndFilteredMonth(`${month}-01-${date.getFullYear()}`);
                                                            setEndDate(date)
                                                        }
                                                        }
                                                        selectsStart
                                                        dateFormat="MM/yyyy"
                                                        showMonthYearPicker
                                                    />
                                                </div>
                                            </div> :
                                            <div className="date-picker">
                                                <DatePicker
                                                    isClearable
                                                    placeholderText="Select a month"
                                                    selected={startDate}
                                                    onChange={(date) => {
                                                        if (date !== null) {
                                                            let month = date.getMonth()
                                                            if (month < 9) {
                                                                month = "0" + (month + 1);
                                                            } else {
                                                                month = (month + 1);
                                                            }
                                                            setFilteredMonth(`${month}-01-${date.getFullYear()}`);
                                                        } else {
                                                            setOptionSelected(rangeSelection.defaultValue);
                                                            setFilteredMonth('');
                                                            setSelectedMonthFilterOption('');
                                                        }
                                                        setStartDate(date)
                                                    }
                                                    }
                                                    selectsStart
                                                    dateFormat="MM/yyyy"
                                                    showMonthYearPicker
                                                />
                                            </div>
                                    }

                                    <S options={mcoSelection.options} className="select-form-combo" id={mcoSelection.id} name={mcoSelection.id}
                                        onChange={(option) => {
                                            setMcoOptionSelected(option);
                                        }
                                        }
                                        defaultValue={mcoSelection.defaultValue} />

                                    <S options={timelinessSelection.options} className="select-form-combo" id={timelinessSelection.id} name={timelinessSelection.id} onChange={(option) => {
                                        setOptionSelected(option);
                                        setSelectedMonthFilterOption(option.value)
                                    }
                                    } defaultValue={timelinessSelection.defaultValue} />
                                </div>
                            </div>
                            <div className="utility-bar-col text-right">
                                <a href="#" className="btn btn-link" onClick={printReport}><FontAwesomeIcon icon={faDownload} className="mr-1" /> Download Report</a>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End Barra filtros Billing --> */}
                    <section className="container-with-sidebar__content-block">
                        {/* <!-- Billing Data Bar --> */}
                        <div className="dash-data-bar mt-2 mb-2">
                            <div className="dash-data-bar__title">
                                <h2>{barFilterText}</h2>
                            </div>
                            {encounters === 'noData' && claims === 'noData' && amountAllowed === 'noData' && amountPaid === 'noData' ?
                                <div className="dash-data-bar__information">
                                    <span className="p-primary">There is no available data to display for the current selection</span>
                                </div> :
                                <>
                                    <div className="dash-data-bar__information">
                                        <div className="dash-data-bar__element">
                                            <div className="dash-data-bar__label">Encounter Transactions</div>
                                            <div className="dash-data-bar__value">
                                                {encounters === '' ? <div className="loader-linear"><span></span><span></span><span></span></div> : numeral(encounters).format('0,0')}
                                            </div>
                                        </div>
                                        <div className="dash-data-bar__element">
                                            <div className="dash-data-bar__label">Claims Transactions</div>
                                            <div className="dash-data-bar__value">{claims === '' ? <div className="loader-linear"><span></span><span></span><span></span></div> : numeral(claims).format('0,0')}</div>
                                        </div>
                                        <div className="dash-data-bar__element">
                                            <div className="dash-data-bar__label">Amount Allowed</div>
                                            <div className="dash-data-bar__value"><span className="money">$</span>&nbsp;{amountAllowed === '' ? <div className="loader-linear"><span></span><span></span><span></span></div> : numeral(amountAllowed).format('0,0.00')}</div>
                                        </div>
                                        <div className="dash-data-bar__element">
                                            <div className="dash-data-bar__label">Amount Paid</div>
                                            <div className="dash-data-bar__value"><span className="money">$</span>&nbsp;{amountPaid === '' ? <div className="loader-linear"><span></span><span></span><span></span></div> : numeral(amountPaid).format('0,0.00')}</div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        {/* <!-- End Billing Data Bar --> */}
                        {/* <!-- Grupo de Gráficos --> */}
                        <div className="row">
                            <div className="col">
                                {!showTableAmountPaidByMCO ?
                                    // Graph 
                                    <div className="panel">
                                        <div className="panel-head">
                                            <div className="title">
                                                <h3>Amount Paid by MCO</h3>
                                            </div>
                                            <div className="util text-right"><button className="btn" onClick={() => { showTableAmountPaidByMCOButton(true) }} ><FontAwesomeIcon icon={faTable} style={{ color: 'rgb(55,136,176)' }} /></button></div>
                                        </div>
                                        <div className="panel-body">
                                            {
                                                pieChartAmountPaidByMCO.length === 0 ?
                                                    <div className="loader"></div> :
                                                    pieChartAmountPaidByMCO === 'noData' ? noData() :
                                                        <PieChart data={pieChartAmountPaidByMCO} dollars />
                                            }
                                        </div>
                                    </div>
                                    :
                                    //Table
                                    <div className="panel">
                                        <div className="panel-head">
                                            <div className="title">
                                                <h3>Amount Paid by MCO</h3>
                                            </div>
                                            <div className="util text-right"><button className="btn" onClick={() => { showTableAmountPaidByMCOButton(false) }}><FontAwesomeIcon icon={faChartPie} style={{ color: 'rgb(55,136,176)' }} /></button></div>
                                        </div>
                                        <div className="panel-body">
                                            <div className="table-scroll">
                                                <table className="table-dashboard">
                                                    <tbody>
                                                        <tr>
                                                            <th>MCO</th>
                                                            <th className="text-right">Amount Paid</th>
                                                            <th className="text-right">Percentage</th>
                                                        </tr>
                                                        {renderTableAmountPaidByMCO()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="col">
                                {!showTableTransactionsByStatus ?
                                    <div className="panel">
                                        <div className="panel-head">
                                            <div className="title">
                                                <h3>Transactions by Status</h3>
                                            </div>
                                            <div className="util text-right"><button className="btn" onClick={() => { showTableTransactionsByStatusButton(true) }}><FontAwesomeIcon icon={faTable} style={{ color: 'rgb(55,136,176)' }} /></button></div>
                                        </div>
                                        <div className="panel-body">
                                            {
                                                pieChartTransactionsByStatus.length === 0 ?
                                                    <div className="loader"></div> :
                                                    pieChartTransactionsByStatus === 'noData' ? noData() :
                                                        <PieChart data={pieChartTransactionsByStatus} />
                                            }
                                        </div>
                                    </div> :
                                    //Table
                                    <div className="panel">
                                        <div className="panel-head">
                                            <div className="title">
                                                <h3>Transactions by Status</h3>
                                            </div>
                                            <div className="util text-right"><button className="btn" onClick={() => { showTableTransactionsByStatusButton(false) }}><FontAwesomeIcon icon={faChartPie} style={{ color: 'rgb(55,136,176)' }} /></button></div>
                                        </div>

                                        <div className="panel-body">
                                            <div className="table-scroll">
                                                <table className="table-dashboard">
                                                    <tbody>
                                                        <tr>
                                                            <th>Status</th>
                                                            <th className="text-right">Total</th>
                                                            <th className="text-right">Percentage</th>
                                                        </tr>
                                                        {renderTableTransactionsByStatus()}
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                {!showTableTimelinessForRegularClaims ?
                                    <div className="panel">
                                        <div className="panel-head">
                                            <div className="title">
                                                <h3>Timeliness for Clean Claims<br />
                                                    <small>(Date Received Vs. Date Paid)</small></h3>
                                            </div>
                                            <div className="util text-right"><button className="btn" onClick={() => { showTableTimelinessForRegularClaimsButton(true) }}><FontAwesomeIcon icon={faTable} style={{ color: 'rgb(55,136,176)' }} /></button></div>
                                        </div>
                                        <div className="panel-body">
                                            {
                                                timelinessForRegularClaims.length === 0 ?
                                                    <div className="loader"></div> :
                                                    timelinessForRegularClaims === 'noData' ? noData() :
                                                        <BarWithLineChart data={timelinessForRegularClaims} />
                                            }
                                        </div>
                                    </div> :
                                    //Table
                                    <div className="panel">
                                        <div className="panel-head">
                                            <div className="title">
                                                <h3>Timeliness for Clean Claims<br />
                                                    <small>(Date Received Vs. Date Paid)</small></h3>
                                            </div>
                                            <div className="util text-right"><button className="btn" onClick={() => { showTableTimelinessForRegularClaimsButton(false) }}><FontAwesomeIcon icon={faChartPie} style={{ color: 'rgb(55,136,176)' }} /></button></div>
                                        </div>
                                        <div className="panel-body">
                                            <div className="table-scroll">
                                                <table className="table-dashboard">
                                                    <tbody>
                                                        <tr>
                                                            <th>Days</th>
                                                            <th className="text-right">Transactions</th>
                                                            <th className="text-right">Amount Paid</th>
                                                        </tr>
                                                        {renderTableTimelinessForRegularClaims()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="col">
                                {!showTableTimelinessForAdjustedClaims ?
                                    <div className="panel">
                                        <div className="panel-head">
                                            <div className="title">
                                                <h3>Timeliness for No Clean Claims<br />
                                                    <small>(Date Received Vs. Date Paid)</small></h3>
                                            </div>
                                            <div className="util text-right"><button className="btn" onClick={() => { showTableTimelinessForAdjustedClaimsButton(true) }}><FontAwesomeIcon icon={faTable} style={{ color: 'rgb(55,136,176)' }} /></button></div>
                                        </div>
                                        <div className="panel-body">
                                            {
                                                timelinessForAdjustedClaims.length === 0 ?
                                                    <div className="loader"></div> :
                                                    timelinessForAdjustedClaims === 'noData' ? noData() :
                                                        <BarWithLineChart data={timelinessForAdjustedClaims} />
                                            }
                                        </div>
                                    </div> :
                                    //Table
                                    <div className="panel">
                                        <div className="panel-head">
                                            <div className="title">
                                                <h3>Timeliness for No Clean Claims<br />
                                                    <small>(Date Received Vs. Date Paid)</small></h3>
                                            </div>
                                            <div className="util text-right"><button className="btn" onClick={() => { showTableTimelinessForAdjustedClaimsButton(false) }}><FontAwesomeIcon icon={faChartPie} style={{ color: 'rgb(55,136,176)' }} /></button></div>
                                        </div>
                                        <div className="panel-body">
                                            <div className="table-scroll">
                                                <table className="table-dashboard">
                                                    <tbody>
                                                        <tr>
                                                            <th>Days</th>
                                                            <th className="text-right">Transactions</th>
                                                            <th className="text-right">Amount Paid</th>
                                                        </tr>
                                                        {renderTableTimelinessForAdjustedClaims()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        {/* TEST REACT TABLE */}
                        {/* <div className="panel mt-2">
                            <ReactTable />
                        </div> */}
                        {/* .TEST REACT TABLE */}
                        <div className="panel mt-2">
                            {/* Graph amountPaidAmountAllowedByMonth */}
                            {!showTableTotalAmountAndPaidMonth ?
                                <div>
                                    <div className="panel-head">
                                        <div className="title">
                                            <h3>Claims - Total Amount Allowed and Paid by Month ($)</h3>
                                        </div>
                                        <div className="util text-right"><button className="btn" onClick={() => { showTableTotalAmountAndPaidMonthButton(true) }}><FontAwesomeIcon icon={faTable} style={{ color: 'rgb(55,136,176)' }} /></button></div>
                                    </div>
                                    < div className="panel-body">
                                        {
                                            amountPaidAmountAllowedByMonth.length === 0 ?
                                                <div className="loader"></div> :
                                                amountPaidAmountAllowedByMonth === 'noData' ? noData() :
                                                    <LineChartFilledTwoDatasets data={amountPaidAmountAllowedByMonth} showTable={false} />
                                        }
                                    </div>
                                </div> :
                                <div>
                                    <div className="panel-head">
                                        <div className="title">
                                            <h3>Claims - Total Amount Allowed and Paid by Month ($)</h3>
                                        </div>
                                        <div className="util text-right"><button className="btn" onClick={() => { showTableTotalAmountAndPaidMonthButton(false) }}><FontAwesomeIcon icon={faChartPie} style={{ color: 'rgb(55,136,176)' }} /></button></div>
                                    </div>
                                    <div className="panel-body">
                                        <div className="table-scroll">
                                            <table className="table-dashboard">
                                                <tbody>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th className="text-right">Amount Paid</th>
                                                        <th className="text-right">Amount Allowed</th>
                                                    </tr>
                                                    <LineChartFilledTwoDatasets data={amountPaidAmountAllowedByMonth} showTable={true} />
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            }
                            {/* .Graph amountPaidAmountAllowedByMonth */}
                        </div>
                        <div className="panel mb-4 mt-2">
                            {/* Graph encountersByMonth */}
                            {!showTableEncounters ?
                                <div>
                                    <div className="panel-head">
                                        <div className="title">
                                            <h3>Encounters - Total Transaccions by Month</h3>
                                        </div>
                                        <div className="util text-right"><button className="btn" onClick={() => { showTableEncountersButton(true) }}><FontAwesomeIcon icon={faTable} style={{ color: 'rgb(55,136,176)' }} /></button></div>
                                    </div>
                                    <div className="panel-body">
                                        {
                                            encountersByMonth.length === 0 ?
                                                <div className="loader"></div> :
                                                encountersByMonth === 'noData' ? noData() :
                                                    <SingleLineChart data={encountersByMonth} />
                                        }
                                    </div>
                                </div> :
                                <div>
                                    <div className="panel-head">
                                        <div className="title">
                                            <h3>Encounters - Total Transaccions by Month</h3>
                                        </div>
                                        <div className="util text-right"><button className="btn" onClick={() => { showTableEncountersButton(false) }}><FontAwesomeIcon icon={faChartPie} style={{ color: 'rgb(55,136,176)' }} /></button></div>
                                    </div>
                                    <div className="panel-body">
                                        <div className="table-scroll">
                                            <table className="table-dashboard">
                                                <tbody>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th className="text-right">Encounters</th>
                                                    </tr>
                                                    <SingleLineChart data={encountersByMonth} showTable={true} />
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            }
                            {/* .Graph encountersByMonth */}
                        </div>
                        {/* <!-- End Grupo de Gráficos --> */}
                    </section>
                </div>
            </div>
        </AuthContextProvider >
    );
}

export default Billing;