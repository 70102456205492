import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import history from "../../../history";
import graphQL from "../../../apis/graphQL";
import { ProvidersContext } from "../../../contexts/ProvidersContext";
import AuthContextProvider from "../../../contexts/AuthContext";

const Disclosure = (props) => {
  // acceptTerms
  const [acceptTerms, setAcceptTerms] = useState(true);
  // loading
  const [loading, setLoading] = useState(false);

  const { npi, firstName, lastName } = useContext(ProvidersContext);

  const connectProvider = async () => {
    setLoading(true);
    console.log("test");
    const response = await graphQL(`query{
            connectProvider(npi: ${parseInt(
              npi
            )},firstName:"${firstName}",lastName:"${lastName}"){
                success
                message
            }
            }`);
    setLoading(false);
    console.log(response);
    if (response.connectProvider) {
      history.push("/connected");
    } else if (response) {
      console.log(response);
      setLoading(false);
      // setGraphQLErrors(response);
    }
    history.push("/connected");
  };

  return (
    <div>
      <div className="container-with-sidebar__heading-bar sticky">
        <div className="row row--centered">
          {" "}
          <div className="col-grow-2">
            <h2>Disclosure</h2>
          </div>
          <div className="col-grow-3">
            <ul className="wizards-steps ml-3">
              <li>
                <span className="sr-only ">Step 1</span>
              </li>
              <li>
                <span className="sr-only ">Step 2</span>
              </li>
              <li>
                <span className="sr-only ">Step 3</span>
              </li>
              <li className="current">
                <span className="sr-only ">Step 4</span>
              </li>
              <li>
                <span className="sr-only ">Step 5</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!-- Contenido --> */}
      <div className="container-with-sidebar__content-block">
        <div className="row">
          <div className="col instructions">
            <p>
              <img
                src="images/image-disclosure.png"
                alt="Search Provider"
                className="mb-2 mt-2"
              />
            </p>
          </div>
          <div className="col-grow-3">
            <p className="text-bold mb-2 p-primary">
              By continuing this process I confirm that I am authorized to
              represent this provider.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse pellentesque nunc vel diam placerat, quis vestibulum
              erat sagittis. Curabitur non ipsum magna. Ut at dictum metus, in
              aliquet tellus. Vivamus dictum risus at porta vestibulum. Morbi
              nec purus arcu. Donec finibus eros non augue lobortis, ut
              porttitor arcu vestibulum. Aenean vestibulum, dui eget rutrum
              porttitor, felis ipsum tristique arcu, ut accumsan diam augue id
              augue. Nunc et maximus metus. Donec eu lectus id justo fringilla
              maximus ut vel ex.
            </p>

            <div className="form-group mt-2">
              <input
                type="checkbox"
                name="termsagree"
                value="0"
                onChange={() => {
                  setAcceptTerms(!acceptTerms);
                }}
              />
              <span className="note-aside">
                I confirm that I am authorized as stated.
              </span>
              {/* <div className="missing-field-message">Accept terms and conditions.</div> */}
            </div>

            <div className="form-group mt-3">
              <Link to="/selectProviderType" className="btn btn-secondary mr-2">
                Cancel
              </Link>
              <button
                type="button"
                className="btn btn-primary"
                disabled={acceptTerms}
                onClick={connectProvider}
              >
                {!loading ? (
                  "Continue"
                ) : (
                  <div className="loader-linear--bg-white">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Disclosure;
